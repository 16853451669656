<template>
	<div>
		<Header :title="isEdit ? '编辑权益' : '新增权益'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form style="margin-top: 20px;" ref="form" name="form" :model="modelRef" scrollToFirstError
				@finish="onSubmit" :labelCol="{span: 4}" :wrapperCol="{span: 14}">
				<a-form-item label="权益名称" name="name" :rules="[{required: true, message:'必填项不允许为空'}]">
					<a-input v-model:value="modelRef.name" :disabled="isSee" placeholder="请输入权益名称"></a-input>
				</a-form-item>

				<!-- <a-form-item label="会员类型" name="memberType" :rules="[{required: true, message:'必选项不允许为空'}]">
					<a-select v-model:value="modelRef.memberType" :disabled="isSee" placeholder="请选择会员类型">
						<a-select-option :value="1">普通会员</a-select-option>
						<a-select-option :value="2">特色会员</a-select-option>
					</a-select>
				</a-form-item> -->

				<a-form-item label="权益图片" name="imageUrl" :rules="[{required: true, message:'必选项不允许为空'}]">
					<a-upload v-model:file-list="fileList" list-type="picture-card" :disabled="isSee" action="/admin/ajaxUpload.do"
						accept="image/*" @change="onUploadChange">
						<div v-if="fileList.length < 1">
							<Icon icon="PlusOutlined"></Icon>
							<div style="margin-top: 8px">Upload</div>
						</div>
					</a-upload>
				</a-form-item>

				<a-form-item name="description" :rules="[{ required: true, message: '必填项不允许为空' }]" label="权益描述">
					<a-textarea v-model:value="modelRef.description" :disabled="isSee" placeholder="请输入描述内容"></a-textarea>
				</a-form-item>

				<a-form-item label="权益类型" name="rightType" :rules="[{required: true, message:'必选项不允许为空'}]">
					<a-select v-model:value="modelRef.rightType" :disabled="isSee" placeholder="请选择权益类型" @change="onTypeChange">
						<a-select-option :value="1">赠送优惠券</a-select-option>
						<a-select-option :value="2">赠送积分</a-select-option>
						<a-select-option :value="3">赠送免费退改签次数</a-select-option>
						<a-select-option :value="4">生日加倍成长值</a-select-option>
						<a-select-option :value="5">活动资格</a-select-option>
						<a-select-option :value="6">赠送一卡通</a-select-option>
						<a-select-option :value="7">赠送商品</a-select-option>
						<a-select-option :value="8">会员升级加速</a-select-option>
						<a-select-option :value="9">会员专属座位</a-select-option>
						<a-select-option :value="10">优惠购票</a-select-option>
					</a-select>
				</a-form-item>
				
				<div v-if="modelRef.rightType === 1">
					<a-form-item label="优惠券" name="rightDetail" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-form-item-rest>
							<selectCoupon v-model:value="modelRef.rightDetail" type="radio" :disabled="isSee"></selectCoupon>
						</a-form-item-rest>
					</a-form-item>
					
					<a-form-item label="优惠券数量" name="rightValue" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input-number v-model:value="modelRef.rightValue" :min="0" :precision="0" :disabled="isSee" placeholder="请输入"></a-input-number>
					</a-form-item>
				</div>
				<div v-if="modelRef.rightType === 2">
					<a-form-item label="积分数量" name="rightValue" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input-number v-model:value="modelRef.rightValue" :min="0" :precision="0" :disabled="isSee" placeholder="请输入"></a-input-number>
					</a-form-item>
				</div>
				<div v-if="modelRef.rightType === 3">
					<a-form-item label="免费次数" name="rightDetail" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input-number v-model:value="modelRef.rightDetail" :min="0" :precision="0" :disabled="isSee" placeholder="请输入"></a-input-number>
					</a-form-item>
				</div>
				<div v-if="modelRef.rightType === 4">
					<a-form-item label="成长值" name="rightDetail" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input-number v-model:value="modelRef.rightDetail" :min="0" :precision="0" :disabled="isSee" placeholder="请输入"></a-input-number>
					</a-form-item>
				</div>
				<div v-if="modelRef.rightType === 6">
					<a-form-item label="一卡通" name="rightDetail" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-form-item-rest>
							<selectOneCard v-model:value="modelRef.rightDetail" type="radio" :disabled="isSee"></selectOneCard>
						</a-form-item-rest>
					</a-form-item>
					
					<a-form-item label="一卡通初始金额" name="rightValue" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input-number v-model:value="modelRef.rightValue" :min="0" :precision="2" :disabled="isSee" placeholder="请输入"></a-input-number>
					</a-form-item>
				</div>
				<div v-if="modelRef.rightType === 7">
					<a-form-item label="商品" name="rightDetail" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-form-item-rest>
							<selectGoods v-model:value="modelRef.rightDetail" type="radio" :disabled="isSee"></selectGoods>
						</a-form-item-rest>
					</a-form-item>
					
					<a-form-item label="商品数量" name="rightValue" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input-number v-model:value="modelRef.rightValue" :min="0" :precision="0" :disabled="isSee" placeholder="请输入"></a-input-number>
					</a-form-item>
				</div>
				<div v-if="modelRef.rightType === 8">
					<a-form-item label="加速百分比" name="rightDetail" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input-number v-model:value="modelRef.rightDetail" :min="0" :precision="0" :disabled="isSee" placeholder="请输入"></a-input-number> %
					</a-form-item>
				</div>

				<a-form-item label="是否默认发放" name="isAutoSend">
					<a-radio-group v-model:value="modelRef.isAutoSend" :disabled="isSee || modelRef.rightType === 7">
						<a-radio :value="0">否</a-radio>
						<a-radio :value="1">是</a-radio>
					</a-radio-group>
				</a-form-item>

				<a-form-item label="是否只可领取一次" name="receiveOnceFlag" :rules="[{ required: true, message: '必选项不允许为空' }]">
					<a-radio-group v-model:value="modelRef.receiveOnceFlag" :disabled="isSee">
						<a-radio :value="0">否</a-radio>
						<a-radio :value="1">是</a-radio>
					</a-radio-group>
				</a-form-item>

				<div v-if="!modelRef.receiveOnceFlag">
					<a-form-item name="receiveTotalNum" label="总可领取次数" :rules="[{ required:true, message: '必填项不允许为空' }]" extra="填0则不限制总可领取次数">
						<a-input-number v-model:value="modelRef.receiveTotalNum" :min="0" :precision="0" :disabled="isSee" placeholder="请输入"></a-input-number>
					</a-form-item>

					<a-form-item name="receivePeriodNum" label="领取周期" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input-number v-model:value="modelRef.receivePeriodNum" :min="0" :precision="0" :disabled="isSee" placeholder="请输入">
							<template #addonAfter>
								<a-select v-model:value="modelRef.receivePeriod" style="width: 60px" :disabled="isSee">
									<a-select-option :value="1">天</a-select-option>
									<a-select-option :value="2">周</a-select-option>
									<a-select-option :value="3">月</a-select-option>
								</a-select>
							</template>
						</a-input-number>
					</a-form-item>
				</div>

				<a-form-item label="状态" name="isDisabled" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-radio-group v-model:value="modelRef.isDisabled" :disabled="isSee">
						<a-radio :value="0">启用</a-radio>
						<a-radio :value="1">禁用</a-radio>
					</a-radio-group>
				</a-form-item>

				<a-row>
					<a-col :offset="6">
						<a-button v-if="!isSee" type="primary" html-type="submit">提交</a-button>
						<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
					</a-col>
				</a-row>
			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import {Icon} from '@/components/icon/icon.js';
	import Header from '@/components/header/header.vue';
	import selectCoupon from '@/components/select/selectCoupon/index.vue';
	import selectGoods from '@/components/select/selectGoods/index.vue';
	import selectOneCard from '@/components/select/selectOneCard/index.vue';
	import {
		getMemberRightDetail,
		saveMemberRight,
		updateMemberRight
	} from "@/service/modules/member";
	export default {
		components: {
			Icon,
			Header,
			selectCoupon,
			selectGoods,
			selectOneCard
		},
		props: {
			isEdit: {
				type: Boolean,
				default: false
			},
			isSee: {
				type: Boolean,
				default: false
			},
			id: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				loading: false,
				fileList: [],
				modelRef: {
					receiveOnceFlag: 1,
					isDisabled: 0,
					receivePeriod: 3,
					isAutoSend: 0
				}
			}
		},
		created() {
			if (this.id) {
				this.getData();
			}
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async onSubmit() {
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				if ([1,6,7].includes(postData.rightType)) {
					postData.rightDetail = postData.rightDetail[0];
				}
				this.loading = true;
				try {
					let ret;
					if (this.isEdit && this.id) {
						ret = await updateMemberRight(postData);
					} else {
						ret = await saveMemberRight(postData)
					}
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('操作成功');
						this.onBack(true);
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getMemberRightDetail({
						id: this.id,
					})
					this.loading = false;
					if (ret.code === 200) {
						if ([1,6,7].includes(ret.data.rightType)) {
							ret.data.rightDetail = [ret.data.rightDetail];
						}
						this.fileList = [{
							uid: 1,
							name: ret.data.imageUrl,
							url: ret.data.imageUrl
						}]
						this.modelRef = ret.data;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onTypeChange(val) {
				this.modelRef.isAutoSend = 0;
				if ([1,6,7].includes(val)) {
					this.modelRef.rightDetail = [];
				} else {
					this.modelRef.rightDetail = undefined;
				}
				this.modelRef.rightValue = undefined;
			},
			onUploadChange(info) {
				if (info.file.status === 'done') {
					//console.log(info.file);
					this.fileList = info.fileList;
					const $fileList = [...this.fileList];
					this.fileList = $fileList.map(file => {
						if (file.response) {
							this.modelRef.imageUrl = file.response.data.imgUrl;
							file.url = file.response.data.imgUrl;
						}
						return file;
					});
				}
			}
		}
	}
</script>

<style>
</style>